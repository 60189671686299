import {
  setDefaultOptions,
  format,
  isDate,
  isBefore,
  isAfter,
  getUnixTime,
  isSameDay,
  isWithinInterval,
  parseISO
} from 'date-fns';
import { ja } from 'date-fns/locale';

setDefaultOptions({
  locale: ja,
  weekStartsOn: 1
});

export const useDate = () => {
  /**
   * @see https://date-fns.org/v2.29.3/docs/format
   */
  const templates = {
    date: 'yyyy/MM/dd',
    date2: 'yyyy-MM-dd',
    dateTime: 'yyyy/MM/dd (E) HH:mm',
    dateTime2: 'yyyy-MM-dd HH:mm',
    dateTime3: 'yyyy/MM/dd HH:mm',
    time: 'HH:mm'
  };

  // 1627298048.123 UNIXタイムスタンプ（ms）
  // 1627298048123 UNIXタイムスタンプ（ms）
  // 1627298048 UNIXタイムスタンプ（s）
  const unixRegexp = {
    msX: /\d{13}/,
    s: /\d{10}.*\d*/
  };

  const formatDate = (value) =>
    value
      ? format(parseISO(value), templates.date2)
      : format(new Date(), templates.date2);

  const formatDateTime = (value) =>
    value
      ? format(parseISO(value), templates.dateTime2)
      : format(new Date(), templates.dateTime2);

  const formatDpDateTime = (value) =>
    value
      ? format(parseISO(value), templates.dateTime3)
      : format(new Date(), templates.dateTime3);

  const formatTime = (value) =>
    value
      ? format(parseISO(value), templates.time)
      : format(new Date(), templates.time);

  // YYYY/MM/DD 形式の日付を取得する
  const localizeDate = (value) => {
    if (!value) return;
    if (unixRegexp.s.test(value)) {
      return format(parseISO(value * 1000), templates.date);
    } else {
      return format(parseISO(value), templates.date);
    }
  };

  // YYYY/MM/DD (ddd) 形式の日付を取得する
  const localizeDateWeek = (value) => {
    if (!value) return;
    if (unixRegexp.s.test(value)) {
      return format(parseISO(value * 1000), templates.dateWeek);
    } else {
      return format(parseISO(value), templates.dateWeek);
    }
  };

  // YYYY/MM/DD (ddd) HH:mm 形式の日時を取得する
  const localizeDateTime = (value) => {
    if (!value) return;
    if (unixRegexp.s.test(value)) {
      return format(parseISO(value * 1000), templates.dateTime);
    } else {
      return format(parseISO(value), templates.dateTime);
    }
  };

  // HH:mm 形式の時刻を取得する
  const localizeTime = (value) => {
    if (!value) return;
    if (unixRegexp.s.test(value))
      return format(parseISO(value * 1000), templates.time);
    else return format(parseISO(value), templates.time);
  };

  const formatDateType = (value) => {
    if (!value) return;
    if (unixRegexp.s.test(value)) return parseISO(value * 1000);
    else return parseISO(value);
  };

  const getTimestamp = () => getUnixTime(new Date());

  const isDateValue = (value) => isDate(value);

  // 現在日時が指定した日時よりも前かを返す
  const isBeforeNow = (value) => isBefore(new Date(), new Date(value));

  // 現在日時が指定した日時よりも後かを返す
  const isAfterNow = (value) => isAfter(new Date(), new Date(value));

  // 現在日時が期間内かを返す
  const isInThePeriod = (startDate, endDate) =>
    isWithinInterval(new Date(), {
      start: startDate,
      end: endDate
    });

  // 同日か返す
  const isSame = (date1, date2) => isSameDay(parseISO(date1), parseISO(date2));

  return {
    unixRegexp,
    formatDate,
    formatDateTime,
    formatTime,
    formatDpDateTime,
    formatDateType,

    localizeDate,
    localizeDateWeek,
    localizeDateTime,
    localizeTime,

    getTimestamp,

    isDateValue,
    isBeforeNow,
    isAfterNow,
    isInThePeriod,
    isSame
  };
};
